"use client";

import { Button } from "@/components/ui/button";
import { useRouter } from "next/navigation";

export function AuthButtons() {
  const router = useRouter();

  return (
    <div className="flex gap-4">
      <Button
        variant="default"
        onClick={() => router.push("/login")}
      >
        Sign In
      </Button>
      <Button
        variant="outline"
        onClick={() => router.push("/signup")}
      >
        Sign Up
      </Button>
    </div>
  );
}